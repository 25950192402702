import React, { useState, useEffect } from 'react';
import { CContainer, CRow, CCol, CButton, CForm  } from '@coreui/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import UserCalls from '../components/UserCalls';
import Spinner from '../components/loader/Spinner';

function CastingCallsCreation() {
  
    const { t } = useTranslation();
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [tag, setTag] = useState('');
    const [roles, setRoles] = useState([]);
    const [audition_detail, setAudition_detail] = useState('');
    const [date_location, setDate_location] = useState('');
    const [datetime, setDate_Time] = useState('');
    const [description, setDescription] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [currentCastingCallId, setCurrentCastingCallId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const language = localStorage.getItem("language");
    const userid = localStorage.getItem('userid');
  
    //----- block ui state
    const [blocking, setBlocking] = useState(false);
    //----- block ui state

    const handleEditClick = (castingCallData) => {
        setTag(castingCallData.tag);
        setAudition_detail(castingCallData.audition_detail);
        setDate_location(castingCallData.location);
        setDate_Time(castingCallData.datetime);
        setDescription(castingCallData.description);
        setEditMode(true); 
        scrollToForm();
        setCurrentCastingCallId(castingCallData._id);

        // Update the roles selection based on the castingCallData
        const selectedRoles = roleOptions.filter(option =>
            castingCallData.roles.some(role => role.name === option.label)
        );
        setRoles(selectedRoles.map(role => role.value));
    };

    const scrollToForm = () => {
        setTimeout(() => {
            document.querySelector('.casting-calls-form').scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };
  
    //------ update casting call 
    const updateCastingCall = async () => {
        if (!currentCastingCallId) {
            console.error('Error: Casting Call ID is a required field');
            toast.error(t('Error updating casting call: Casting Call ID is a required field'));
            return;
        }
        setBlocking(true);

        const formData = new URLSearchParams();
        formData.append('casting_call_id', currentCastingCallId);
        formData.append('tag', tag);
        // formData.append('roles', roles);
       
        roles.forEach((role, index) => {
            formData.append(`roles`, role);
        });
    
        formData.append('audition_detail', audition_detail);
        formData.append('location', date_location);
        formData.append('datetime', datetime);
        formData.append('description', description);
        if (tag === '' || roles.length === 0 || audition_detail === '' || date_location === '' || datetime === '' || description === '') {
            toast.error(`${t('Please fill in all required fields')}!`);
        }else{
            setLoading(true);
            try {

                const token = localStorage.getItem('token');
                const response = await fetch(`${apiUrl}/casting-call`, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/x-www-form-urlencoded', 
                        'accept-language': language
                    },
                    body: formData,   });

                const data = await response.json();
                fetchData();
                setLoading(false);
                
                if (!response.ok) {
                    setBlocking(false);
                    toast.error(data.message);
                    throw new Error(`Failed to update casting call: ${data.message}`);
                }

                setBlocking(false);
                console.log(data.message);
                toast.success(data.message);
                setTimeout(() => {
                    document.querySelector('.user-calls-wrapper').scrollIntoView({ behavior: 'smooth' });
                }, 100);

                setTag('');
                setAudition_detail('');
                setDate_location('');
                setDate_Time('');
                setDescription('');
                setEditMode(false); 
                setRoles([]);
                setCurrentCastingCallId('');

            } catch (error) {
                // Error handling code here
                console.log('Error updating casting call:', error);
                toast.error(error);
                setBlocking(false);
            }
        }
        setLoading(false);
        setBlocking(false);
    };
      
    //------- creating casting calls
    const [castingCalls, setCastingCalls] = useState([]);
    const createCastingCall = async () => {
        setLoading(true);
        setBlocking(true);
        if (tag === '' || roles.length === 0 || audition_detail === '' || date_location === '' || datetime === '' || description === '') {
            toast.error(`${t('Please fill in all required fields')}!`);
            setBlocking(false);
        }else{
                try {
                    const token = localStorage.getItem('token');
                    const response = await fetch(`${apiUrl}/casting-call`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                            'accept-language': language
                        },
                        body: JSON.stringify({
                            tag: tag,
                            roles: roles, // this is now an array
                            audition_detail: audition_detail,
                            location: date_location,
                            datetime: datetime,
                            description: description,
                        }),
                    });
                    const data = await response.json();
            
                    if (!response.ok) {
                        setBlocking(false);
                        throw new Error('Failed to create casting call: ' + data.message);
                    }
            
                    if (data.result === 'success') {
                        console.log(data.message);
                        toast.success(data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                        fetchData();
                        setLoading(false);
                        setTimeout(() => {
                            document.querySelector('.user-calls-wrapper').scrollIntoView({ behavior: 'smooth' });
                        }, 100);

                        setTag('');
                        setAudition_detail('');
                        setDate_location('');
                        setDate_Time('');
                        setDescription('');
                        setRoles([]);
                        setCurrentCastingCallId('');
                        setBlocking(false);
                    }
                } catch (error) {
                    console.error('Error creating casting call:', error);
                    toast.error(error.message);
                    setBlocking(false);
                }
        }
        setLoading(false);
        setBlocking(false);
    };

    const handleSubmit = () => {
        if (editMode) {
            updateCastingCall(currentCastingCallId);
        } else {
            createCastingCall();
        }
    };
    
    //------- Fetching all casting calls
    const fetchData = async () => {
        setLoading2(true);
        try {

            const token = localStorage.getItem('token');
            const response = await fetch(`${apiUrl}/casting-call`,{
                method: 'Get',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'accept-language': language
                }
            });

            const data = await response.json();
            setCastingCalls(data.data);
            setLoading2(false);

            if (data.result === 'success') {
                console.log('Casting calls fectched succussfully!')
            }

            if (data.result === 'error') {
                if (data.message === 'Token expired') {
                    navigate('/login');
                }
            }

        } catch (error) {
          console.error('Error fetching data:', error);
          if (error.response && error.response.data) {
            if (error.response.data.message === 'Invalid token') {
              navigate('/login');
            }
          } else {
            // Handle other types of errors if necessary
            console.error('Unexpected error:', error.message);
          }
        }
      setLoading2(false);
    };

    useEffect(() => {
        console.log('Component mounted or updated');
        fetchData();
    }, []);
    

    //------- Fetching all Roles
    const [userRoles, setUserRoles] = useState([]);
    const roleOptions = userRoles.map(role => ({
        value: role._id,
        label: role.name
    }));

    const handleRoleChange = (selectedOptions) => {
        const selectedRoleIds = selectedOptions.map(option => option.value);
        setRoles(selectedRoleIds);
    };

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${apiUrl}/roles`,{
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'accept-language': language
                    }
                });

                const data = await response.json();
                setUserRoles(data.data);

                if (!response.ok) {
                    console.log('Network response was not ok');
                    // toast.error(data.message);
                }

                if (data.result === 'success') {
                    console.log(data.message);
                }
                
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };
        fetchRoles();
    }, []);

    //---- delete casting calls 
    const onDelete = (callId) => {
        setBlocking(true);
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const token = localStorage.getItem('token');

        fetch(`${apiUrl}/casting-call/${callId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'accept-language': language
            }
        })
        .then(response => {
            if (!response.ok) {
                setBlocking(false);
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if (data.result === 'success') {
                setBlocking(false);
                toast.success(data.message); //'Casting call deleted successfully'
                fetchData();
            } else {
                setBlocking(false);
                toast.error(data.message);
            }
        })
        .catch(error => {
            setBlocking(false);
            console.error('Error:', error);
            toast.error('Error deleting casting call');
        });
    };

    //--- handle cancel click ---//
    const handleCancel = () => {
        setTag('');
        setAudition_detail('');
        setDate_location('');
        setDate_Time('');
        setDescription('');
        setRoles([]);
        setCurrentCastingCallId('');
        setEditMode(false); 
    }

    // Get today's date in 'YYYY-MM-DD' format
    const today = new Date().toISOString().split('T')[0];
  
    return (
        <>
            <BlockUi tag="div" blocking={blocking}>
                {/*/--- navbar ---/*/}
                <Navbar authorized={true} />

                <CContainer>
                    <div className='page-wrapper position-relative'>
                        <div className='page-heading-wrapper'>
                            <h2 className='page-heading'>{t('Create Casting Call')}</h2>
                        </div>

                        {/*/--- Casting calls form start here ---/*/}
                        <CRow>
                            <CCol lg={12} className='mx-auto'>
                                <div className='casting-calls-form'>
                                    <CForm>
                                        <CRow>
                                            <CCol lg={6}>
                                                <div className='w-100 mb-3'>
                                                    <input 
                                                        type='text' 
                                                        placeholder={`${t('Add Title')} *`}
                                                        value={tag}
                                                        onChange={(e)=>setTag(e.target.value)}
                                                    />
                                                </div>
                                            </CCol>
                                            <CCol lg={6}>
                                                <div className='w-100 mb-3'>
                                                    <Select
                                                        isMulti
                                                        name="roles"
                                                        options={roleOptions}
                                                        className="select-input roles-select-wrapper"
                                                        classNamePrefix="select"
                                                        placeholder={`${t('Select Roles')}*`}
                                                        onChange={handleRoleChange}
                                                        value={roleOptions.filter(option => roles.includes(option.value))}
                                                    />
                                                </div>
                                            </CCol>
                                            <CCol lg={6}>
                                                <div className='w-100 mb-3'>
                                                    <input 
                                                        type='text' 
                                                        placeholder={`${t('Audition Details')} *`}
                                                        value={audition_detail}
                                                        onChange={(e)=>setAudition_detail(e.target.value)}
                                                    />
                                                </div>
                                            </CCol>
                                            
                                            <CCol lg={6}>
                                                <div className='w-100 mb-3'>
                                                    <input 
                                                        type='text' 
                                                        placeholder={`${t('Location')} *`}
                                                        value={date_location}
                                                        onChange={(e)=>setDate_location(e.target.value)}
                                                    />
                                                </div>
                                            </CCol>
                                            <CCol lg={12}>
                                                <div className='w-100 mb-3'>
                                                    <label className='mb-3' style={{ fontWeight:'600' }}>{t('Audition Deadline*')}</label>
                                                    <input 
                                                        type='date' 
                                                        placeholder='Audition Deadline*'
                                                        value={datetime} // Assuming datetime is in the format 'YYYY-MM-DD'
                                                        min={today}
                                                        onChange={(e)=>setDate_Time(e.target.value)}
                                                    />
                                                </div>
                                            </CCol>
                                            <CCol lg={12}>
                                                <div className='w-100 mb-3'>
                                                    <textarea 
                                                        placeholder={`${t('Description')}*`} 
                                                        rows={6}
                                                        value={description}
                                                        onChange={(e)=>setDescription(e.target.value)}
                                                    />
                                                </div>
                                            </CCol>
                                            <CCol lg={6}>
                                                <div className='w-100 mb-3'>
                                                    <CButton className='secondary_outline_btn w-100' onClick={()=>handleCancel()} >{t('Cancel')}</CButton>
                                                </div>
                                            </CCol>
                                            <CCol lg={6}>
                                                <div className='w-100 mb-3'>
                                                    <CButton className='primary_btn w-100' onClick={handleSubmit} disabled={loading}>
                                                        {loading? 
                                                            <>{t('Submitting..')}</> 
                                                            :
                                                            <>
                                                                {t('Submit')}
                                                            </>
                                                        }
                                                    </CButton>
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </div>
                            </CCol>
                        </CRow>
                        {/*/--- Casting calls form end's here ---/*/}

                        {/*/--- user casting calls section start here ---/*/}
                        <section className='user-calls-wrapper' id='user-calls-wrapper'>
                            <CRow>
                                <CCol lg={12}>
                                    <h2 className='page-heading'>{t('your calls')}</h2>
                                </CCol>

                                {loading2 ? (
                                    <Spinner />
                                ) : (
                                    <>
                                        {castingCalls.length > 0?
                                            <>
                                                {Array.isArray(castingCalls) && castingCalls.map((call, index) => (
                                                    <CCol lg={6} key={index} className='py-3'>
                                                        <UserCalls
                                                            title={call.user_id && call.user_id.first_name ? call.user_id.first_name : 'Unknown'} 
                                                            callId={call._id}
                                                            onDelete={onDelete}
                                                            role={call.roles && call.roles.length > 0 ? call.roles.map(role => role.name).join(", ") : t('No role specified')} 
                                                            job={call.tag}
                                                            dateLocation={call.location || 'Location not specified'} 
                                                            details={call.audition_detail || 'No details provided'}
                                                            description={call.description || 'No description available'}
                                                            onEdit={handleEditClick}
                                                            datetime={call.datetime || 'Date not specified'}
                                                        />
                                                    </CCol>
                                                ))}
                                            </>
                                        :
                                            <>
                                            <p className='text-center py-5'>{t('Your created casting calls will appear here!')}</p></>
                                        }
                                        
                                    </>
                                )}

                            </CRow>
                        </section>
                        {/*/--- user casting calls section end's here ---/*/}
                    </div>
                </CContainer>

                {/*/--- Footer ---/*/}
                <Footer />

                {/*/--- copy right ---/*/}
                <CopyRight />
            </BlockUi>
        </>
  )
}

export default CastingCallsCreation;
