import React, { useEffect, useState } from "react";
import { CContainer, CRow, CCol, CButton } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

//-------- components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CopyRight from "../components/CopyRight";
import SuccessStoryCard from "../components/SuccessStoryCard";
import ProfileCard from "../components/ProfileCard";
import { Helmet } from "react-helmet";

//--------- images
import profile from "../assets/images/user-avatar.jpg";
import discover from "../assets/images/discover.png";
import axios from "axios";
import Spinner from "../components/loader/Spinner";
import bannerBg from '../assets/images/profile-banner.png';

function FilmPitchingBooth() {

  const { t } = useTranslation();
  const [profiles, setProfiles] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const imgeUrl = process.env.REACT_APP_IMAGE_URL;
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasProfileVideo, setHasProfileVideo] = useState(false);
  
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Film Pitching Booth Page",
    "description": "A dedicated space for filmmakers to pitch ideas and scripts across formats, boosting project visibility to producers and film funds.",
    "url": `${window.location.origin}/film-pitching-booth`,
    "publisher": {
      "@type": "Organization",
      "name": "Cinewoo",
      "url": "https://cinewoo.com",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cinewoo.com/logo.png", // Replace with actual logo URL
        "width": 250,
        "height": 60
      }
    },
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Film Pitching Booth",
          "item": `${window.location.origin}/film-pitching-booth`
        }
      ]
    }
  };

  //---- fetching success stories
  const fetchStories = async () => {
    setLoading(true);
    try {
      const language = localStorage.getItem("language");
      const response = await axios.get(`${apiUrl}/success-stories`, {
        headers: {
          'Content-Type': 'application/json',
          'accept-language': language
        }
      });
      // console.log('Success Stories Response:', response.data);
      setStories(response.data.data);
    } catch (error) {
      console.error('Error fetching success stories:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProfiles = async () => {
    setLoading(true);
    try {
      const language = localStorage.getItem("language");
      const response = await axios.get(`${apiUrl}/users`, {
        headers: {
          'Content-Type': 'application/json',
          'accept-language': language
        }
      });
      if (response.data && Array.isArray(response.data.data)) {
        const profileData = response.data.data.map((user) => {
          const imgSrc = user.userDetail?.profile_image
            ? `${imgeUrl}/${user.userDetail.profile_image}`
            : `${profile}`;
          return {
            ...user,
            imgSrc,
          };
        });
  
        // Set profiles without filtering
        setProfiles(profileData);
        
        // Check if there are more than 5 profiles
        const hasMultipleProfiles = profileData.length > 5;
        setHasProfileVideo(hasMultipleProfiles);
      } else {
        console.error("Unexpected API response structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching profiles:", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchProfiles();
    fetchStories();
  }, []);

  const location = useLocation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (location.hash === '#profiles-wrapper') {
        scrollToSection();
      } else {
        window.scrollTo(0, 0);
      }
    }, 100);

    return () => clearTimeout(timeoutId); 
  }, [location]);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/success-stories");
  };

  const handleNavigate2 = () => {
    navigate("/industry-profiles");
  };

  const handleNavigate3 = () => {
    navigate("/casting-room");
  }

  const scrollToSection = () => {
    const castingCallsSection = document.getElementById('profiles-wrapper');
    if (castingCallsSection) {
      castingCallsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
      
      {/*/--- navbar ---/*/}
      <Navbar />

      {/*/--- banner section ---/*/}
      <section className="banner-wrapper" style={{backgroundImage: `url(${bannerBg})`}}>
        <div className="h-100-p"> {/* banner-wrapper-inner-layer */}
          <CContainer className="h-100-p">
            <CRow className="align-items-center h-100-p">
              <CCol md={12}>
                <div className="banner-content text-center">
                  <h2>
                    {t('pitch your')}
                    <br />
                    {t('film')}
                  </h2>
                  <p>{t('Enter Our Film Pitching Booth Now')}</p>
                  <CButton className="explore-btn mt-5" onClick={scrollToSection}>{t('Enter')}</CButton>
                </div>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </section>

      {/*/--- profile cards ---/*/}
      <section className="profile-wrapper" id='profiles-wrapper'>
        <CContainer className="h-100-p">
          <CRow className="align-items-center h-100-p">
            <CCol md={12}>
              <div className="profile-content">
                <h2>{t('Pitch now')}</h2>
                <h4>{t('Select profile')}</h4>
              </div>
            </CCol>
            <CCol md={12}>
              <div className="profile-content-cards">
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                  {profiles.length > 0 ?
                    profiles.slice(0, 5).map((user) => (
                      <ProfileCard
                        key={user._id}
                        userid={user._id}
                        title={`${user.first_name}`}
                        imgSrc={user.imgSrc}
                        link={`/user-profile/${user._id}/${user.first_name}${user.sir_name}`}
                      />
                    ))
                    :
                    <p>No profile found!</p>
                  }
                  
                  </>
                )}
              </div>
            </CCol>
            <CCol md={12}>
              <div className="text-center">
              {hasProfileVideo ? (
                <CButton className="primary_btn" onClick={handleNavigate2}>
                  {t('show all')}
                </CButton>
              ) : (
                <></>
              )}
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>

      {/*/--- success stories ---/*/}
      <section className="success-stories-wrapper">
        <CContainer className="h-100-p">
          <CRow className="align-items-center h-100-p">
            <CCol md={12}>
              <div className="success-stories-content mb-5">
                <h2 className="section-heading">{t('success')}</h2>
                <h3 className="section-sub-heading">{t('stories')}</h3>
              </div>
            </CCol>
            {loading ? (
              <Spinner />
            ) : (
              stories.length > 0 ? (
                stories.slice(0, 2).map(story => (
                  <CCol md={12} lg={6} key={story._id}>
                    <SuccessStoryCard
                      imgSrc={story.image ? `${imgeUrl}/${story.image}` : null}
                      videoSrc={story.video ? `${imgeUrl}/${story.video}` : null}
                      title={story.name}
                      description={story.description}
                      location={story.location}
                      datetime={story.datetime}
                      displayType={story.display_type}
                    />
                  </CCol>
                ))
              ) : (
                <p>{t("No success story found!")}</p>
              )
            )}
            {stories.length > 2?
              <CCol md={12}>
                <div className="text-center mt-5">
                    <CButton className="primary_btn" onClick={handleNavigate}>
                      {t('show all')}
                    </CButton>
                </div>
              </CCol>
              :
              <></>
            }
          </CRow>
        </CContainer>
      </section>

      {/*/--- how it works section ---/*/}
      <section className="how-it-works-wrapper">
        <CContainer className="h-100-p">
          <CRow>
            <CCol md={6}>
              <div className="work-process">
                <h2>{t('how it works?')}</h2>
                <div className="process-step">
                  <div>
                    <span>{t('Browse expert profiles:')} </span>
                    {t('Explore a diverse array of industry professionals ready to elevate your vision.')}
                  </div>
                </div>
                <div className="process-step">
                  <div>
                    <span>{t('Pitch in 60 seconds:')} </span>
                    {t('Craft your compelling vision through a concise video presentation.')}
                  </div>
                </div>
                <div className="process-step">
                  <div>
                    <span>{t('Attract Producers and Funders:')} </span>
                    {t('Receive valuable insights and guidance to refine your pitch and propel your project forward.')}
                  </div>
                </div>
              </div>
            </CCol>
            <CCol md={6}>
              <h2 className="discover-heading">{t("Let's kick things off")}</h2>
              <div className="discover-wrapper">
                <img src={discover} alt="discover" />
                <CButton className="primary_btn" onClick={handleNavigate3}>{t('Discover More')}</CButton>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>

      {/*/--- Footer ---/*/}
      <Footer />

      {/*/--- copy right ---/*/}
      <CopyRight />
    </>
  );
}

export default FilmPitchingBooth;
