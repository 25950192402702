import React, { useState, useEffect } from 'react';
import { CContainer, CRow, CCol, CButton, CForm } from '@coreui/react';
import { CNav, CNavLink, CTabPane, CNavItem, CTabContent } from '@coreui/react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

//-------- components
import MediaCard from './MediaCard';
import Spinner from './loader/Spinner';

function UserMedia(props) {

    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState(1);
    const { userid, username } = useParams();
    // const userid = localStorage.getItem('targetUserId');
    const [loadingPhotos, setLoadingPhotos] = useState(false);
    const [loadingFilms, setLoadingFilms] = useState(false);
    const language = localStorage.getItem("language");

    //------ Create Film API integration ------//
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const imgeUrl = process.env.REACT_APP_IMAGE_URL;

    //------ Fetch Photos API integration ------//
    const [galleryPhotos, setGalleryPhotos] = useState([]);

    const fetchPhotos = async () => {
        setLoadingPhotos(true);
        try {
            const response = await fetch(`${apiUrl}/photos`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'accept-language': language
                },
                body: JSON.stringify({
                    user_id: userid,
                }),
            });
            if (!response.ok) {
                console.log(data.message);
            }

            const data = await response.json();
            setGalleryPhotos(data.data);

            if (data.result === 'success') {
                // console.log(data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoadingPhotos(false);
    };

    useEffect(() => {
        fetchPhotos();
    }, []);

    //------ Fetch Films API integration ------//
    const [galleryFilms, setGalleryFilms] = useState([]);

    const fetchFilms = async () => {
        setLoadingFilms(true);
        try {

            const response = await fetch(`${apiUrl}/films`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'accept-language': language
                },
                body: JSON.stringify({
                    user_id: userid,
                }),
            });

            if (!response.ok) {
                console.log(data.message);
            }

            const data = await response.json();
            setGalleryFilms(data.data);
            setLoadingFilms(false);

            if (data.result === 'success') {
                // console.log(data.message);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoadingFilms(false);
    };
    useEffect(() => {
        fetchFilms();
    }, []);

    const handleDeletePhoto = async (photoId) => {
        // Call fetchPhotos again to refresh the gallery after deletion
        fetchFilms();
        fetchPhotos();
        console.log(`Deleted photo with id: ${photoId}`);
    };

    const handleDeleteFlim = async (photoId) => {
        // Call fetchPhotos again to refresh the gallery after deletion
        fetchFilms();
        console.log(`Deleted photo with id: ${photoId}`);
    };


    return (
        <>
            <section className='media-wrapper'>
                <CContainer>
                    <CRow>
                        <CCol lg={12}>
                            <div className='media-tabs'>
                                <CNav variant="tabs" role="tablist">
                                    <CNavItem>
                                        <CNavLink
                                            href="#!"
                                            active={activeKey === 1}
                                            onClick={() => setActiveKey(1)}
                                        >
                                            {t('Head Shots')}
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem>
                                        <CNavLink
                                            href="#!"
                                            active={activeKey === 2}
                                            onClick={() => setActiveKey(2)}
                                        >
                                            {t('Films')}
                                        </CNavLink>
                                    </CNavItem>
                                </CNav>
                                <CTabContent>
                                    <CTabPane role="tabpanel" aria-labelledby="photos-tab" visible={activeKey === 1}>
                                        {/* tab content for photos */}
                                        <div className='media-tabs-wrapper'>
                                            <CRow>
                                                {loadingPhotos ? (
                                                    <Spinner />
                                                ) : galleryPhotos.length === 0 ? (
                                                    <div className='white-text p-5 w-100 text-center'>No photos available</div>
                                                ) : (
                                                    <>
                                                        {galleryPhotos.map((photo, index) => (
                                                            <CCol key={index} lg={2} md={3} xs={6}>
                                                                <MediaCard
                                                                    imgSrc={`${imgeUrl}/${photo.photo}`}
                                                                    mediaId={photo._id}
                                                                    clsname="photo-classname d-none"
                                                                    title="Photo Title"
                                                                    onDelete={handleDeletePhoto}
                                                                    mediaType="photo"
                                                                    editProfile={props.editProfile}
                                                                />
                                                            </CCol>
                                                        ))}
                                                    </>
                                                )}
                                            </CRow>
                                        </div>

                                    </CTabPane>
                                    <CTabPane role="tabpanel" aria-labelledby="films-tab" visible={activeKey === 2}>
                                        {/* tab content for films */}
                                        <div className='media-tabs-wrapper'>
                                            <CRow>

                                                {galleryFilms.length === 0 ? (
                                                    <div className='text-white p-5 w-100 text-center'>No record available</div>
                                                ) : (
                                                    galleryFilms.map((film, index) => (
                                                        <CCol key={index} lg={2} md={3} xs={6}>
                                                            <MediaCard
                                                                imgSrc={`${imgeUrl}/${film.thumbnail}`}
                                                                mediaId={film._id}
                                                                clsname="film-classname d-block"
                                                                title={film.title}
                                                                onDelete={handleDeleteFlim}
                                                                mediaType="film"
                                                                editProfile={props.editProfile}
                                                            />
                                                        </CCol>
                                                    ))
                                                )}

                                            </CRow>
                                        </div>
                                    </CTabPane>
                                </CTabContent>
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
            </section>
        </>
    )
}

export default UserMedia;
