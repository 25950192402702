import { useState } from 'react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CModal, CModalBody, CModalFooter, CButton } from '@coreui/react';

function UserCalls({ callId, onDelete,title,dateLocation,details,job,role,onEdit,description,datetime }) {

    const [visible, setVisible] = useState(false)
    const { t } = useTranslation();
    const [menuListOpen, setMenuListOpen] = useState(false);
    const navigate = useNavigate();
    const handleMenuList = () => {
        setMenuListOpen(!menuListOpen);
    }

    const handleDelete = () => {
        onDelete(callId); 
    }

    const handleEdit = () => {
        const dateOnly = datetime.split("T")[0];
        onEdit({
            _id: callId,
            tag: job,
            roles: role.split(", ").map(r => ({ name: r })), // Assuming roles are passed as a string of names
            audition_detail: details,
            location: dateLocation,
            datetime: dateOnly, // Pass only the date part
            description: description
        });
    };

    const formatDate = (datetime) => {
        const date = new Date(datetime);
        return date.toLocaleDateString();
    };

    const isActive = (datetime) => {
      const currentDate = new Date().toISOString().split('T')[0];
      const callDate = datetime.split('T')[0];
      return callDate >= currentDate;
    };


  return (
    <>
      <div className='avail-casting-call-wrapper position-relative user-own-call'>
        <div className='edit-calls-menu'>
            <Link onClick={handleMenuList}>
                <svg width="32" height="8" viewBox="0 0 42 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" fill="#D9D9D9"/>
                    <circle cx="21" cy="4" r="4" fill="#D9D9D9"/>
                    <circle cx="38" cy="4" r="4" fill="#D9D9D9"/>
                </svg>
            </Link>
        </div>
        {menuListOpen ?
            <div className='edit-calls-menu-list'>
                <Link onClick={handleEdit}>{t('Edit')}</Link>
                <Link onClick={()=>setVisible(true)}>{t('Delete')}</Link>
                {/* <Link to={`/user-auditions/${callId}`}>{t('View Auditions')}</Link> */}
            </div>
            :
            <></>
        }
        <div className='avail-casting-call-header'>
            <h3><Link to={`/edit-user-profile`}>{title}</Link></h3>
            <div className='d-flex justify-content-between'>
              <div>
                <h5>{t("Role")}</h5>
                <h4>{role}</h4>
              </div>
              <div className='user-call-status'>
                <span>{isActive(datetime) ? t("Active") : t("Closed")}</span>
              </div>
            </div>
        </div>
        <div className='avail-casting-call-body'>
            <h5>{job}</h5>
            <h5>{t("Details")}</h5>
            <p>
               {details}
            </p>
            <h5>{t("Description")}</h5>
            <div className='casting-call-desc'>
              <p>{description}</p>
            </div>
            <Link to={`/role-description/${callId}`} className='casting-detail-read-btn d-block'>{t("Read More")}</Link>
        </div>
        <div className='avail-casting-call-footer'>
            <h5>{t('Dates & Locations')}</h5>
            <p>{formatDate(datetime)}, {dateLocation}</p>
        </div>
      </div>

      {/*/--- modal to verify delete operation ---/*/}
      <CModal
        alignment="center"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="VerticallyCenteredExample"
        className='confirmation-modal'
      >
        <CModalBody className='py-5'>
          <p className='confirmation-txt'>{t("Do you realy want to delete this casting call?")}</p>
        </CModalBody>
        <CModalFooter>
          <CButton className='confirmation-btn secondary_outline_btn ' onClick={() => setVisible(false)}>
            {t("No")}
          </CButton>
          <CButton className='confirmation-btn primary_btn' onClick={handleDelete}>{t("Yes")}</CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default UserCalls;
