import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function BlogsCard({blogImg, blogWriter, blogPostDate, blogTitle, blogDetails, blogId}) {

    const { t } = useTranslation();

    return (
        <>
            <div className='blog-card-wrapper'>
                <Link to={`/blog-detail/${blogId}`}>
                    <div className='blog-card-img'>
                        <img src={blogImg} alt='blog'/>
                    </div>
                </Link>
                <div className='blog-card-content'>
                    <div className='blog-by-txt pt-3'>
                        <p>{t("By")} <span> {blogWriter} </span> | {blogPostDate} </p>
                        <h2>
                            <Link to={`/blog-detail/${blogId}`}>{blogTitle}</Link>
                        </h2>
                    </div>
                    <div className='blog-details'>
                        <p>{blogDetails}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogsCard;