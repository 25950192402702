import React, { useRef, useCallback, useState, useEffect } from "react";
import { CButton } from "@coreui/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spinner from "../Chat/loader/Spinner";
import "../Chat/loader/Spinner.css";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const PitchVision = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [recordedVideoURL, setRecordedVideoURL] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0); // Timer state
  const mediaRecorderRef = useRef(null);
  const videoLiveRef = useRef(null); // Ref for live video
  const timerRef = useRef(null); // Ref for timer interval
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const authToken = localStorage.getItem("token");
  const language = localStorage.getItem("language");
  const { castingCallId } = useParams();

  const startWebcam = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: false,
          sampleRate: 44100,
          channelCount: 2,
        },
        video: true,
      })
      .then((stream) => {
        videoLiveRef.current.srcObject = stream;

        // Ensure video starts playing after source is fully loaded
        videoLiveRef.current.onloadedmetadata = () => {
          videoLiveRef.current.play().catch((error) => {
            console.error("Error playing video:", error);
          });
        };
      })
      .catch((error) => {
        console.error("Error accessing media devices:", error);
        toast.error("Failed to access camera or microphone.");
      });
  }, []);

  const stopWebcam = useCallback(() => {
    if (videoLiveRef.current) {
      const stream = videoLiveRef.current.srcObject;
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      videoLiveRef.current.srcObject = null;
    }
  }, []);

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => [...prev, data]);
    }
  }, []);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    setElapsedTime(0); // Reset timer
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: false,
          sampleRate: 44100,
          channelCount: 2,
        },
        video: true,
      })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        );
        mediaRecorderRef.current.start();

        // Automatically stop recording after 60 seconds
        setTimeout(() => {
          if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.stop();
            setCapturing(false);
            clearInterval(timerRef.current); // Stop the timer
          }
        }, 60000);
      })
      .catch((error) => {
        console.error("Error accessing media devices:", error);
        toast.error("Failed to access camera or microphone.");
      });
  }, [handleDataAvailable]);

  useEffect(() => {
    let timerInterval;
    if (capturing) {
      timerInterval = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
      timerRef.current = timerInterval; // Store timer reference
    } else {
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval); // Cleanup on unmount
  }, [capturing]);

  const handleStopCaptureClick = useCallback(() => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.stop();
      setCapturing(false);
      clearInterval(timerRef.current); // Stop the timer
    }
  }, []);

  const handleCancelClick = useCallback(() => {
    setCapturing(false);
    setRecordedChunks([]);
    setRecordedVideoURL(null);
    setElapsedTime(0); // Reset timer
    clearInterval(timerRef.current); // Stop the timer
  }, []);

  const handlePreview = useCallback(() => {
    const blob = new Blob(recordedChunks, { type: "video/webm" });
    const videoURL = URL.createObjectURL(blob);
    setRecordedVideoURL(videoURL);
  }, [recordedChunks]);

  const handleDownload = useCallback(() => {
    const blob = new Blob(recordedChunks, { type: "video/webm" });
    const videoFile = new File([blob], "audition.webm", { type: "video/webm" });
    setLoading(true);
    props.setBlocking(true);

    const formData = new FormData();
    formData.append("pitch_user_to", castingCallId);
    formData.append("pitch_video", videoFile);

    axios
      .post(`${apiUrl}/filmpitching/create`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
          "accept-language": language,
        },
      })
      .then((response) => {
        props.setBlocking(false);
        toast.success(response.data.message);
        setRecordedChunks([]);
        stopWebcam(); // Turn off the camera after submission
        navigate("/industry-profiles");
        setLoading(false);
      })
      .catch((error) => {
        props.setBlocking(false);
        toast.error(error.response?.data?.message || "Error uploading video");
        setLoading(false);
      });
  }, [recordedChunks, apiUrl, authToken, castingCallId, stopWebcam]);

  useEffect(() => {
    startWebcam(); // Start live video feed on component mount
    return () => stopWebcam(); // Stop live video feed on component unmount
  }, [startWebcam, stopWebcam]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60).toString().padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${mins}:${secs}`;
  };

  return (
    <>
      <div className="video-recorder-wrapper">
        <video
          ref={videoLiveRef}
          autoPlay
          muted
          playsInline
          className="video-live"
        ></video>
        {capturing && (
          <div className="recoding-timer">
            <span>{formatTime(elapsedTime)}</span>
          </div>
        )}
      </div>

      {recordedVideoURL && (
        <>
          <h2 className="mt-4">{t("Recording Preview")}</h2>
          <div className="preview-container">
            <ReactPlayer
              className="recorded-video-preview"
              url={recordedVideoURL}
              width={300}
              height={230}
              controls={true}
              volume={1.0}
            />
          </div>
        </>
      )}

      <div className="video-recording-handlers text-start text-lg-end mt-4">
        {!capturing && recordedChunks.length === 0 && (
          <CButton
            className="secondary_btn mt-lg-0 mt-3"
            onClick={handleStartCaptureClick}
          >
            {t("Start")}
          </CButton>
        )}

        {capturing && (
          <CButton
            className="secondary_btn mt-lg-0 mt-3"
            onClick={handleStopCaptureClick}
          >
            {t("Stop")}
          </CButton>
        )}

        {!capturing && recordedChunks.length > 0 && (
          <>
            <CButton
              className="secondary_outline_btn me-lg-4 me-2 mt-lg-0 mt-3"
              onClick={handleCancelClick}
            >
              {t("Cancel")}
            </CButton>
            <CButton
              className="primary_outline_btn me-2 mt-lg-0 mt-3"
              onClick={handlePreview}
            >
              {t("Preview")}
            </CButton>
            <CButton
              className="primary_btn mt-lg-0 mt-3"
              onClick={handleDownload}
              disabled={loading}
            >
              {loading ? <Spinner /> : t("Submit")}
            </CButton>
          </>
        )}
      </div>
    </>
  );
};


export default PitchVision;
