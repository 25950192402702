import React from 'react';
import b1 from '../../assets/images/blogs/b1.png';
import { useTranslation } from 'react-i18next';

function ReleventBlogCard({ blog }) {

    const { t } = useTranslation();

    return (
        <>
            <div className='relevent-blog-card-wrapper'>
                <div className='relevent-blog-img'>
                    <img src={b1} alt='blog'/>
                </div>
                <div className='relevent-blog-details mt-2'>
                    <h3>{blog.title}</h3>
                    <p>
                        {t("Published on")}: 
                        <span className='mx-2'>{new Date(blog.createdAt).toLocaleDateString()}</span>
                        | <span className='ms-2'>23 min read</span>
                    </p>
                </div>
            </div>
        </>
    )
}

export default ReleventBlogCard;