import React from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';

const VideoAudioRecorder = () => {
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({ video: true, audio: true });

  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <h2>Video and Audio Recorder</h2>
      <p>Status: {status}</p>

      {!mediaBlobUrl && (
        <div>
          {status === "recording" ? (
            <button
              onClick={stopRecording}
              style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', margin: '10px' }}
            >
              Stop Recording
            </button>
          ) : (
            <button
              onClick={startRecording}
              style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', margin: '10px' }}
            >
              Start Recording
            </button>
          )}
        </div>
      )}

      {mediaBlobUrl && (
        <div>
          <h3>Recorded Video Preview</h3>
          <video src={mediaBlobUrl} controls style={{ width: '100%', maxWidth: '600px' }} />
          <button
            onClick={() => window.location.reload()}
            style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', margin: '10px' }}
          >
            Record Again
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoAudioRecorder;
