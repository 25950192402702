import React, { useEffect, useState } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import Spinner from '../components/loader/Spinner';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CNav, CNavLink, CTabPane, CNavItem, CTabContent } from '@coreui/react';


//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import SimpleVideoPlayer from '../components/SimpleVideoPlayer';

function UserFilmPitchingBooth() {

    const [activeKey, setActiveKey] = useState(1);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [filmPitchings, setFilmPitchings] = useState([]);
    const [sentFilmPitchings, setSentFilmPitchings] = useState([]);
    const [receivedFilmPitchings, setReceivedFilmPitchings] = useState([]);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const videoBaseUrl = process.env.REACT_APP_IMAGE_URL;
    const [loading, setLoading] = useState(false);

    // const fetchFilmPitchings = async () => {
    //     setLoading(true);
    //     try {
    //         const token = localStorage.getItem('token');
    //         const language = localStorage.getItem("language");
    //         const response = await fetch(`${apiUrl}/filmpitching`, {
    //             method: 'GET',
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 'accept-language': language
    //             },
    //         });
    //         const data = await response.json();
    //         if (response.ok) {
    //             setFilmPitchings(data.data);
    //             // console.log(data.data);
    //             setLoading(false);
    //         } else {
    //             console.error('Failed to fetch film pitchings:', data.message);
    //             if (data.message === 'Invalid token') {
    //                 navigate('/login');
    //             }
    //             setLoading(false);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching film pitchings:', error);
    //         setLoading(false);
    //     }
    // };

    const fetchSentFilmPitchings = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const language = localStorage.getItem("language");
            const response = await fetch(`${apiUrl}/sent-pitches`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'accept-language': language
                },
            });
            const data = await response.json();
            if (response.ok) {
                setSentFilmPitchings(data.data);
                setLoading(false);
            } else {
                console.error('Failed to fetch film pitchings:', data.message);
                if (data.message === 'Invalid token') {
                    navigate('/login');
                }
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching film pitchings:', error);
            setLoading(false);
        }
    };

    const fetchReceivedFilmPitchings = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const language = localStorage.getItem("language");
            const response = await fetch(`${apiUrl}/receive-pitches`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'accept-language': language
                },
            });
            const data = await response.json();
            if (response.ok) {
                setReceivedFilmPitchings(data.data);
                setLoading(false);
            } else {
                console.error('Failed to fetch film pitchings:', data.message);
                if (data.message === 'Invalid token') {
                    navigate('/login');
                }
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching film pitchings:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        // fetchFilmPitchings();
        fetchReceivedFilmPitchings();
        fetchSentFilmPitchings();
    }, [apiUrl]);


    const handleDeletePitchVideo = async (pitchId) => {
        // fetchFilmPitchings();
        console.log(`Deleted video with id: ${pitchId}`);
        fetchSentFilmPitchings();
        fetchReceivedFilmPitchings();
    };

    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar authorized={true} />

            <section className='h-100-v'>
                <CContainer>
                    <div className='page-wrapper position-relative pt-4 pb-3 mb-4'>
                        <div className='page-heading-wrapper'>
                            <h2 className='page-heading mb-0'>{t('Pitching Box')}</h2>
                            <p>{t("Your video pitch will be automatically deleted from your box 30 days after it is sent.")}</p>
                        </div>
                    </div>
                    <div className='user-film-booth-wrapper'>
                        
                        {loading ? (
                            <Spinner />
                        ) : (
                            <CRow>
                                <CCol lg={12}>
                                    <div className='media-tabs'>
                                        <CNav variant="tabs" role="tablist">
                                            <CNavItem>
                                                <CNavLink
                                                    href="#!"
                                                    active={activeKey === 1}
                                                    onClick={() => setActiveKey(1)}
                                                >
                                                    {t('Sent Pitches')}
                                                </CNavLink>
                                            </CNavItem>
                                            <CNavItem>
                                                <CNavLink
                                                    href="#!"
                                                    active={activeKey === 2}
                                                    onClick={() => setActiveKey(2)}
                                                >
                                                    {t('Received Pitches')}
                                                </CNavLink>
                                            </CNavItem>
                                        </CNav>
                                        <CTabContent>
                                            <CTabPane role="tabpanel" aria-labelledby="photos-tab" visible={activeKey === 1}>
                                                {/* tab content for photos */}
                                                <div className='media-tabs-wrapper pt-4'>
                                                    <CRow>
                                                        {sentFilmPitchings.length > 0 ?
                                                            <>
                                                                {sentFilmPitchings.map((film, index) => (
                                                                    <CCol key={index} lg={4} md={6}>
                                                                        <SimpleVideoPlayer
                                                                            title={`${film.pitch_user_to?.first_name} ${film.pitch_user_to?.sir_name}`} // Display the user's first and last name
                                                                            videoUrl={`${videoBaseUrl}/${film.pitch_video}`} // Full path to the video
                                                                            width='100%'
                                                                            height='100%'
                                                                            userProfile={true}
                                                                            btnContent={t('View')}
                                                                            profilelink={`/user-profile/${film.pitch_user_to?._id}/${film.pitch_user_to?.first_name}${film.pitch_user_to?.sir_name}`}
                                                                            userId={film.pitch_user_to?._id}
                                                                            deleteId={true}
                                                                            videoId={film._id}
                                                                            videoType='Pitching Video'
                                                                            onDelete={handleDeletePitchVideo}
                                                                        />
                                                                    </CCol>
                                                                ))}
                                                            </>
                                                        :
                                                            <div className='text-white d-flex justify-content-center align-items-center' style={{ height: '80vh' }}>
                                                                <p className='text-center'>{t("This is where your pitches will appear after they have been submitted!")}</p>
                                                            </div>
                                                        }
                                                    </CRow>
                                                </div>
                                            </CTabPane>
                                            <CTabPane role="tabpanel" aria-labelledby="films-tab" visible={activeKey === 2}>
                                                {/* tab content for films */}
                                                <div className='media-tabs-wrapper pt-4'>
                                                    <CRow>
                                                        {receivedFilmPitchings.length > 0? 
                                                            <>
                                                                {receivedFilmPitchings.map((film, index) => (
                                                                    <CCol key={index} lg={4} md={6}>
                                                                        <SimpleVideoPlayer
                                                                            title={`${film.pitch_user_by?.first_name} ${film.pitch_user_by?.sir_name}`} // Display the user's first and last name
                                                                            videoUrl={`${videoBaseUrl}/${film.pitch_video}`} // Full path to the video
                                                                            width='100%'
                                                                            height='100%'
                                                                            userProfile={true}
                                                                            btnContent={t('View')}
                                                                            profilelink={`/user-profile/${film.pitch_user_by?._id}/${film.pitch_user_by?.first_name}${film.pitch_user_by?.sir_name}`}
                                                                            userId={film.pitch_user_by?._id}
                                                                            deleteId={true}
                                                                            videoId={film._id}
                                                                            videoType='Pitching Video'
                                                                            onDelete={handleDeletePitchVideo}
                                                                        />
                                                                    </CCol>
                                                                ))}
                                                            </>
                                                            :
                                                            <CCol lg={12}>
                                                                <div className='text-white d-flex justify-content-center align-items-center' style={{ height: '80vh' }}>
                                                                    {t("No record Found!")}
                                                                </div>
                                                            </CCol>
                                                        }
                                                    </CRow>
                                                </div>
                                            </CTabPane>
                                        </CTabContent>
                                    </div>
                                </CCol>
                                
                            </CRow>
                        )}
                    </div>
                </CContainer>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default UserFilmPitchingBooth;
