import React, { useEffect, useState } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import PitchVision from '../components/PitchVision';

function PitchProfile() {
    
    const [blocking, setBlocking] = useState(false);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <BlockUi tag="div" blocking={blocking}>
                {/*/--- Navbar ---/*/}
                <Navbar authorized={true} />

                {/* casting audition section */}
                <section className='casting-audition-wrapper'>
                    <CContainer className="h-100-p">
                        <CRow className="align-items-center h-100-p">
                            <CCol md={8} className='mx-auto'>
                                <PitchVision setBlocking={setBlocking} />
                            </CCol>
                        </CRow>
                    </CContainer>
                </section>

                {/*/--- Footer ---/*/}
                <Footer />

                {/*/--- copy right ---/*/}
                <CopyRight />
            </BlockUi>
        </>
    )
}

export default PitchProfile;
