import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import CopyRight from '../../components/CopyRight';
import { CButton, CCol, CContainer, CRow } from '@coreui/react';
import { CModal, CModalBody } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import BlogSlider from '../../components/blogs/BlogSlider';
import Spinner from '../../components/loader/Spinner';
import InstanceBlogs from '../../components/blogs/InstanceBlogs';

//--- images
import shareIcon from '../../assets/images/share-icon.svg';
import quoteI from '../../assets/images/blogs/quote.png';

import linkedInI from '../../assets/images/social-icons/linkedIn.svg';
import fbI from '../../assets/images/social-icons/fb.svg';
import twitterI from '../../assets/images/social-icons/twitter.svg';
import discordI from '../../assets/images/social-icons/discord.svg';
import whatsappI from '../../assets/images/social-icons/whatsapp.svg';
import copyI from '../../assets/images/social-icons/copy-icon.svg';

function BlogDetails() {

    const { t } = useTranslation();
    const { blogId } = useParams();
    const [alertTxt, setAlertTxt] = useState('');
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [blogDetails, setBlogDetails] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const [sliderImages, setSliderImages] = useState([]);
    const [recentBlogs, setRecentBlogs] = useState([]);
    const [relatedBlogs, setRelatedBlogs] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const imgBaseUrl = process.env.REACT_APP_IMAGE_URL;
    const shareUrl = process.env.REACT_APP_SHARE_URL;

    useEffect(() => {
        const token = localStorage.getItem("token");
        setIsLoggedIn(!!token);
    }, []);  

    const fetchBlogDetails = async () => {
        setLoading(true);
        try {
            const language = localStorage.getItem("language");
            const response = await fetch(`${apiUrl}/blogs/${blogId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'accept-language': language
                }
            });
            const data = await response.json();
            if (response.ok) {
                setBlogDetails(data.data.blog);
                setGalleryImages(data.data.galleryImages || []);
                setSliderImages(data.data.sliderImages || []);
                setRecentBlogs(data.data.recentBlogs);
                setRelatedBlogs(data.data.relatedBlogs);
            } else {
                console.error('Failed to fetch blog details:', data.message);
            }
        } catch (error) {
            console.error('Error fetching blog details:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBlogDetails();
    }, []);

    return (
        <>
            <Navbar authorized={false} classTitle='blog-nav' />

            <section className='blog-detail-page-wrapper'>
                <CContainer>
                    <CRow>
                        <CCol lg={12} className='d-lg-block d-none'>
                            <div className='bread-crumbs pb-4'>
                                <p>
                                    <Link to='/'>Home</Link> /
                                    <Link to='/blogs'>Blog</Link> /
                                    <Link to='/blogs'>BlogInnerPage</Link>
                                </p>
                            </div>
                        </CCol>
                        <CCol lg={8} className='d-lg-block d-none'>
                            {blogDetails && (
                                <div className='blog-title mb-4 d-lg-flex d-none'>
                                    <div style={{ flex: '1' }}>
                                        <h2>{blogDetails.title}</h2>
                                        <p>
                                            Published on: <span className='me-2'>{new Date(blogDetails.createdAt).toLocaleDateString()}</span>
                                            | <span className='ms-2'>23 min read</span>
                                        </p>
                                    </div>
                                    {isLoggedIn && (
                                        <CButton className='share-btn' onClick={() => setVisible(true)} style={{ position: 'inherit' }}>
                                            <img src={shareIcon} alt='icon' className='me-1' />
                                            <span>{t("Share")}</span>
                                        </CButton>
                                    )}
                                </div>
                            )}
                        </CCol>
                        <CCol lg={4} className='d-lg-block d-none'>
                            <div className="relevent-blogs-wrapper">
                                <h2>{t("Recent Blogs")}</h2>
                            </div>
                        </CCol>
                        <CCol lg={8}>
                            {loading?
                                <Spinner/>
                            :
                                <div className='blog-details-content'>
                                    {blogDetails && (
                                        <>
                                            {/* <div className='blog-title mb-4 d-lg-flex d-none'>
                                                <div>
                                                    <h2>{blogDetails.title}</h2>
                                                    <p>
                                                        Published on: <span className='me-2'>{new Date(blogDetails.createdAt).toLocaleDateString()}</span> 
                                                        | <span className='ms-2'>23 min read</span>
                                                    </p>
                                                </div>
                                                {isLoggedIn && (
                                                    <CButton className='share-btn' onClick={() => setVisible(true)} style={{ position: 'inherit', width:'170px' }}>
                                                        <img src={shareIcon} alt='icon' className='me-1' />
                                                        <span>{t("Share")}</span>
                                                    </CButton>
                                                )}
                                            </div> */}

                                            {/* Blog Slider */}
                                            <BlogSlider sliderImages={sliderImages}  />

                                            <div className='blog-txt-details mt-5' dangerouslySetInnerHTML={{ __html: blogDetails.content }} />

                                            {/* <div className='quote-txt mt-4'>
                                                <img className='me-3' src={quoteI} alt='icon' />
                                                <p>
                                                    With the rapid and constant changes in computer and information technology, the conten.
                                                </p>
                                            </div> */}

                                            <div className='blog-gallery mt-5'>
                                                <CRow>
                                                    {galleryImages.map((image, index) => {
                                                        // Determine column width based on the repeating pattern
                                                        const columnPattern = [8, 4, 4, 8, 8, 4, 4, 8]; // Define the repeating pattern
                                                        const columnSize = columnPattern[index % columnPattern.length]; // Cycle through the pattern

                                                        return (
                                                            <CCol lg={columnSize} className='mb-4' key={image._id}>
                                                                <div className='blog-gallery-img'>
                                                                    <img src={`${imgBaseUrl}/${image.image}`} alt={`gallery-${index}`} />
                                                                </div>
                                                            </CCol>
                                                        );
                                                    })}
                                                </CRow>
                                            </div>
                                        </>
                                    )}
                                </div>
                            }
                        </CCol>
                        <CCol lg={4} className='d-lg-block d-none'>
                            <InstanceBlogs recentBlogs={recentBlogs} relatedBlogs={relatedBlogs} />
                        </CCol>
                    </CRow>
                </CContainer>
            </section>

            <Footer />
            <CopyRight />

            {/*/--- Share Modal ---/*/}
            <CModal
                className='photos-upload-modal'
                alignment="center"
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="VerticallyCenteredExample"
            >
                <CModalBody>
                <div className='upload-photo-modal-body-content film-upload-content py-3 px-4'>
                    <h3 className='text-start'>{t('Share this blog')}</h3>
                    <div className='social-platforms d-flex align-items-center justify-content-between mt-4'>
                    {/* LinkedIn */}
                    <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(`${shareUrl}/blog-detail/${blogId}`)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={linkedInI} alt='LinkedIn' />
                    </a>

                    {/* Facebook */}
                    <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`${shareUrl}/blog-detail/${blogId}`)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={fbI} alt='Facebook' />
                    </a>

                    {/* Twitter */}
                    <a
                        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(`${shareUrl}/blog-detail/${blogId}`)}&text=${encodeURIComponent('Check out this blog!')}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={twitterI} alt='Twitter' />
                    </a>

                    {/* Discord */}
                    <a
                        href={`https://discord.com/channels/@me`} // Discord doesn't have direct sharing, so adjust as needed
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={discordI} alt='Discord' />
                    </a>

                    {/* WhatsApp */}
                    <a
                        href={`https://wa.me/?text=${encodeURIComponent(`Check out this blog: ${shareUrl}/blog-detail/${blogId}`)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={whatsappI} alt='WhatsApp' />
                    </a>
                    </div>

                    <div className='copy-url-wrapper mt-5 position-relative'>
                    <input 
                        type='text' 
                        defaultValue={`${shareUrl}/blog-detail/${blogId}`} 
                        placeholder='Blog URL'
                        readOnly
                    />
                    <Link 
                        to='#'
                        onClick={() => {
                        const link = `${shareUrl}/blog-detail/${blogId}`;
                        navigator.clipboard.writeText(link)
                            .then(() => {
                            setAlertTxt('Link Copied!');
                            setTimeout(() => setAlertTxt(''), 3000);
                            })
                            .catch((err) => {
                            console.error('Failed to copy the link: ', err);
                            setAlertTxt('Failed to copy!');
                            setTimeout(() => setAlertTxt(''), 3000);
                            });
                        }}
                    >
                        <img src={copyI} alt='icon'/>
                    </Link>
                    <span>{alertTxt}</span>
                    </div>
                </div>
                <CButton className='close_modal_btn' onClick={() => setVisible(false)}></CButton>
                </CModalBody>
            </CModal>
        </>
    );
}

export default BlogDetails;
