import React, { useEffect, useState } from 'react';
import { CRow, CCol, CButton } from '@coreui/react';
import { CModal, CModalBody } from '@coreui/react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Spinner from './loader/Spinner';

function PackageCard(props) {

    const setBlocking = props.setBlocking;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [loading, setLoading] = useState(false);
    const language = localStorage.getItem("language");
  

    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const fetchSubscription = async () => {
            try {
                const response = await axios.get(`${apiUrl}/subscription`,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.data.result === 'success') {
                    setSubscription(response.data.data);
                    setIsSubscribed(true);
                    setLoading(false);
                } else {
                    console.log('Failed to fetch subscription data');
                    setLoading(false);
                }
            } catch (error) {
                console.log('Error fetching data');
                setLoading(false);
            } finally {
                console.log('fetching data');
                setLoading(false);
            }
        };

        fetchSubscription();
    }, []);

    const handleSubscribe = async () => {
        setVisible(false);
        setBlocking(true);
        
        const token = localStorage.getItem('token');
        if (token) {
            if (!isSubscribed) {
                try {
                    const response = await fetch(`${apiUrl}/subscription`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                            'accept-language': language
                        },
                        body: JSON.stringify({ price: 0, duration: 'monthly' })
                    });
                    const data = await response.json();
                    if (data.message === "Your Already Subscribed") {
                        setIsSubscribed(true); 
                        toast.info(data.message);
                    } else {
                        toast.success(t('You are subscribed!'));
                        setIsSubscribed(true);
                        setSubscription(data.data); 
                    }
                } catch (error) {
                    console.error('Failed to subscribe:', error);
                    toast.error(t('Subscription failed!'));
                    if (error.response.data.message === 'Token expired') {
                        navigate('/login');
                    }
                } finally {
                    setBlocking(false);
                }
            } else {
                setBlocking(false);
            }
        } else {
            navigate('/login');
            setBlocking(false);
        }
    };

    const handleNavigate = () => {
        const token = localStorage.getItem('token');
        if (token) {
            setVisible(!visible);
        }else{
            navigate('/login');
        }
    }

    return (
        <>
            <div className='monthly-package-wrapper'>
                <CRow>
                    <CCol lg={5} className='mx-auto'>
                        <div className='monthly-package-content'>
                            <div className='monthly-package-card-body'>
                                <div className='top-circle'></div>
                                <div className='package-name mt-5'>
                                    <h2>{subscription && subscription.price ? `$ ${subscription.price.toFixed(2)}` : "$0.00"}</h2>
                                    <h3 className='duration-text'>{subscription ? subscription.duration : t('Monthly')}</h3>
                                </div>
                                <div className='package-duration mt-3'>
                                    <h4 className='mb-3'>{t('1 Year Free Membership')}</h4>
                                </div>

                                {loading?
                                    <Spinner/>
                                :
                                    <CButton className='primary_outline_btn mt-3 mb-3' onClick={() => handleNavigate()}  disabled={isSubscribed}>
                                        {isSubscribed?
                                            <>{t('Subscribed')}</>
                                        :
                                            <>{t('Subscribe')}</>
                                        }
                                    </CButton>
                                }
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </div>

                {/* subscribe modal */}
                <CModal
                    alignment="center"
                    visible={visible}
                    onClose={() => setVisible(false)}
                    aria-labelledby="VerticallyCenteredExample"
                    className='subscrition-modal'
                    size='lg'
                >
                    <CModalBody>
                        <CButton className='close_modal_btn' onClick={() => setVisible(false)}></CButton>
                        <div className='subscrition-modal-body-content d-flex flex-column align-items-center justify-content-center'>
                            <h2>{t('Subscribe')}<br/>{t('Now')}</h2>
                            <p>
                                {t('Be Among the Elite 300: Enjoy a Year of Free Community Membership with cinewoo')}
                            </p>
                            <CButton className='subscribe-btn mt-4' onClick={(e)=>{handleSubscribe();}} >{t('Subscribe')}</CButton>
                        </div>
                    </CModalBody>
                    
                </CModal>
        </>
    )
}

export default PackageCard;
