import React from 'react';
import blogImg from '../../assets/images/no-blog.png';
import { useTranslation } from 'react-i18next';

function EmptyBlog() {

    const { t } = useTranslation();

    return (
        <>
            <div className='blog-card-wrapper'>
                <div className='blog-card-img'>
                    <img src={blogImg} alt='blog'/>
                </div>
                <div className='blog-card-content'>
                    <div className='blog-by-txt pt-3'>
                        <p>{t("By")} <span> Cinewoo </span> | MM DD,YY  </p>
                        <h2>{t("Blog title")}<br/>{t("coming soon")}: </h2>
                    </div>
                    <div className='blog-details'>
                        <p>{t("Blog is coming soon")}..</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmptyBlog;