import { CCarousel, CCarouselItem, CImage } from '@coreui/react';
import React from 'react';

function BlogSlider({ sliderImages }) {
    const imgBaseUrl = process.env.REACT_APP_IMAGE_URL;
    console.log('sliderImages : ', sliderImages);

    return (
        <div className='blog-slider-wrapper'>
            <CCarousel indicators className='h-100'>
                {sliderImages.map((image, index) => (
                    <CCarouselItem key={index} className='h-100'>
                        <CImage className="d-block w-100" src={`${imgBaseUrl}/${image.image}`} alt={`slide ${index + 1}`} />
                    </CCarouselItem>
                ))}
            </CCarousel>
        </div>
    );
}

export default BlogSlider;
