import React, { useEffect } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import instaIcon from '../assets/images/insta.svg';
import linkedIcon from '../assets/images/linkedin.svg';
import fbIcon from '../assets/images/fb.svg';
import youtubeIcon from '../assets/images/youtube.svg';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';

function AboutUs() {

    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/*/--- navbar ---/*/}
            <Navbar />

            {/*/---- About Us section ----/*/}
            <section className='privacy-policy-wrapper'>
                <CContainer>
                    <CRow>
                        <CCol md={12}>
                            <div className='privacy-policy-wrapper-content w-100'>
                                <div className='privacy-policy-card'>
                                    <div className="section-header mb-5">
                                        <h2 className="section-heading">{t('ABOUT US')}</h2>
                                    </div>

                                    <div className='policy-content'>
                                        <h3 className='section-sub-heading text-start'>{t('Welcome to cinewoo')}</h3>
                                        <p>
                                            {t("Cinewoo is owned and operated by cinewoo studios Inc. a Canadian for profit entity incorporated in Ontario - Canada. At Cinewoo, we’re on a mission to empower emerging talents within the Canadian film industry. Our platform is designed to connect filmmakers, writers, actors, and industry professionals in a dynamic and inclusive multifaceted digital space, where creativity thrives, opportunities abound, and connections lead to impactful collaborations.")}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Our Name')}</h3>
                                        <p><span className='yellow-txt'>{t('cinewoo')}</span> {t('derives its name from two core inspirations:')}</p>
                                        <ul>
                                            <li>
                                                <span>{t('Cine')}: </span>
                                                {t("A nod to cinema, representing the art, craft, and industry of filmmaking.")}
                                            </li>
                                            <li>
                                                <span>{t('Woo')}: </span>
                                                {t("A term symbolizing attraction, encouragement, and engagement. It reflects the platform's mission to draw talents together, spark creativity, and foster meaningful collaborations.")}
                                            </li>
                                        </ul>
                                        <p>
                                            {t("The name embodies Cinewoo’s vision of being a magnetic hub for Canadian cinema – empowering filmmakers, writers, actors, and industry professionals to connect, interact, and discover opportunities that bring their creative visions to life. It’s not just a name; it’s a promise to celebrate and elevate talent.")}
                                        </p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Our Mission')}</h3>
                                        <p>{t('Cinewoo is a pioneering platform dedicated to fostering and promoting contemporary Canadian cinema. To empower Canadian film talent by providing tools, resources, and connections that foster creativity, inclusivity, and diversity while amplifying their voices on both national and international stages.We’re here to help individuals from all sectors of the film industry network, empower themselves through education, share their talents, find their next industry role, and secure funding for their upcoming projects.')}</p> 
                                    </div>

                                    
                                    <div className='policy-content mt-5'>
                                        <h3>{t('Our Vision')}</h3>
                                        <p>{t('Cinewoo was born from the belief that Canada’s diverse and dynamic film industry deserves a dedicated space to thrive. We aim to become the ultimate digital hub where Canadian filmmakers can connect, interact, and discover. ')}</p> 
                                        <p>{t("Our platform is designed to streamline the pathways for discovering talent and developing projects, making it easier for Canadian voices to be heard and celebrated both nationally and internationally.")}</p>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Our Goals')}</h3>
                                        <ul>
                                            <li>
                                                <span>- </span>
                                                {t('Create a user-centric online community for Canadian film talents and industry professionals through our platform and its social media channels')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('To comply with legal obligations, respond to lawful requests from public authorities, and protect our rights.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t("Revolutionize, modernize and streamline film industry processes like networking, casting and film pitching")}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t("Educate and empower our community with cutting-edge resources")}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t("Support emerging talents, as well as seasoned professionals, in the industry")}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t("Allow Canadian filmmakers to showcase their talents to the world")}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Our Features')}</h3>
                                        <p>{t("Now in our Soft Launch phase, we are excited to introduce two of our flagship services")}:</p>
                                        <ul>
                                            <li>
                                                <span>- </span>
                                                {t('Online Casting Room: Simplifies the casting process by bringing auditions online. This feature allows users to respond to casting calls by submitting audition tapes that casting directors can view and respond to directly within the platform.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('Film Pitching Booth: Offers filmmakers and writers an opportunity to pitch their film ideas and scripts to producers in 60 seconds or less to secure funding and begin effective collaborations')}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Our Future')}</h3>
                                        <p>{t("In the near future, Cinewoo will be introducing additional features to enhance your experience")}:</p>
                                        <ul>
                                            <li>
                                                <span>- </span>
                                                {t('Online Film Library: Discover curated collections of “à la carte” short and featured films highlighting the diversity and talent of Canadian filmmakers when our Cinematheque goes live.')}
                                            </li>
                                            <li>
                                                <span>- </span>
                                                {t('Video content & Live-Streamed Event Coverage: Bring the energy of film festivals and industry events to your screen and stay connected to the latest happenings in Canadian cinema with behind-the-scenes content in our upcoming Video Hub.')}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Our Story')}</h3>
                                        <p>{t('Cinewoo began as a vision from our founder, who, as a filmmaker, writer, and producer, understands the challenges faced by film talents firsthand. Inspired to create a space where others could overcome these barriers, Cinewoo was built to provide solutions that our founder wished existed earlier in their career.')}</p> 
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Building a Vibrant Community')}</h3>
                                        <p>{t('In cinewoo we are eager and proud to  collaborate with film schools, festivals, and industry institutions to support Canadian talent and amplify their impact. At Cinewoo, we’re more than just a platform; we’re a community that celebrates the diversity and creativity of Canadian cinema')}</p> 
                                    </div>

                                    <div className='policy-content mt-5'>
                                        <h3>{t('Join Us')}</h3>
                                        <p>
                                            {t('Thank you so much for joining and supporting the Cinewoo community as we grow our platform. If you have any questions, comments, or feedback at all, please reach out to us at ')}
                                            <Link className='d-inline' to='mailto:info@cinewoo.com'>info@cinewoo.com</Link>
                                            {t(" or use the contact form ")}
                                            <Link className='d-inline' to='/contact-us'>Contact Us</Link>
                                            {t(" on our site! ")}
                                        </p> 
                                        <p>{t('You can also follow us on Instagram, Facebook, LinkedIn, and YouTube to keep up with updates, and don’t forget to subscribe to our newsletter! ')}</p>
                                        <div className='mt-3'>
                                            <Link to='https://www.instagram.com/cinewoo/?hl=en' style={{marginRight:'15px'}}>
                                                <img src={instaIcon} alt="social icon" />
                                            </Link>
                                            
                                            <Link to='https://www.facebook.com/cinewoo' style={{marginRight:'15px'}}>
                                                <img src={fbIcon} alt="social icon" />
                                            </Link>
                                            
                                            <Link to='https://www.linkedin.com/company/cinewoo/?viewAsMember=true' style={{marginRight:'15px'}}>
                                                <img src={linkedIcon} alt="social icon" />
                                            </Link>
                                            
                                            <Link to='https://www.youtube.com/@cinewoostudios' >
                                                <img src={youtubeIcon} alt="social icon" />
                                            </Link>
                                        </div>
                                        <p>
                                            Cinewoo is your platform to Connect. Interact. Discover. Join us today and take the first step in your creative journey.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default AboutUs;