import React, { useRef, useCallback, useState, useEffect } from 'react';
import { CButton } from '@coreui/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from '../Chat/loader/Spinner';
import '../Chat/loader/Spinner.css';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';

const VideoRecorder = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [recordedVideoURL, setRecordedVideoURL] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const mediaRecorderRef = useRef(null);
  const videoLiveRef = useRef(null); // For live video preview
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const authToken = localStorage.getItem('token');
  const language = localStorage.getItem('language');
  const { castingCallId } = useParams();

  const startWebcam = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          channelCount: 1,
        },
        video: true,
      })
      .then((stream) => {
        videoLiveRef.current.srcObject = stream;

        // Add an event listener to ensure the video plays after the source is loaded
        videoLiveRef.current.onloadedmetadata = () => {
          videoLiveRef.current.play().catch((error) => {
            console.error("Error playing video:", error);
          });
        };
      })
      .catch((error) => {
        console.error("Error accessing media devices:", error);
        toast.error("Failed to access camera or microphone.");
      });
  }, []);

  const stopWebcam = useCallback(() => {
    const stream = videoLiveRef.current?.srcObject;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      videoLiveRef.current.srcObject = null;
    }
  }, []);

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => [...prev, data]);
    }
  }, []);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    setElapsedTime(0);

    navigator.mediaDevices
      .getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          channelCount: 1,
        },
        video: true,
      })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: 'video/webm',
        });
        mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
        mediaRecorderRef.current.start();
      })
      .catch((error) => {
        console.error('Error accessing media devices:', error);
        toast.error('Failed to access camera or microphone.');
      });
  }, [handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, []);

  const handlePreview = useCallback(() => {
    const blob = new Blob(recordedChunks, { type: 'video/webm' });
    setRecordedVideoURL(URL.createObjectURL(blob));
  }, [recordedChunks]);

  const handleCancelClick = useCallback(() => {
    setCapturing(false);
    setRecordedChunks([]);
    setRecordedVideoURL(null);
    setElapsedTime(0);
  }, []);

  const handleDownload = useCallback(() => {
    const blob = new Blob(recordedChunks, { type: 'video/webm' });
    setLoading(true);
    props.setBlocking(true);

    const formData = new FormData();
    formData.append('audition', blob);
    formData.append('casting_call_id', castingCallId);

    axios
      .post(`${apiUrl}/casting-call/audition`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data',
          'accept-language': language,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        setRecordedChunks([]);
        setLoading(false);
        props.setBlocking(false);
        stopWebcam(); // Turn off the camera after submission
        navigate('/casting-room');
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || 'Error uploading video');
        setLoading(false);
        props.setBlocking(false);
      });
  }, [recordedChunks, apiUrl, authToken, castingCallId, language, stopWebcam]);

  useEffect(() => {
    startWebcam(); // Start webcam feed on component mount
    return () => stopWebcam(); // Stop webcam feed when component unmounts
  }, [startWebcam, stopWebcam]);

  useEffect(() => {
    let timer;
    if (capturing) {
      timer = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [capturing]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${mins}:${secs}`;
  };

  return (
    <>
      <div className="video-recorder-wrapper">
        <video
          ref={videoLiveRef}
          autoPlay
          muted
          playsInline
          className="video-live"
        ></video>
        {capturing && <div className="recoding-timer">{formatTime(elapsedTime)}</div>}
      </div>

      {recordedVideoURL && (
        <>
          <h2 className="mt-4">{t("Recording Preview")}</h2>
          <div className="preview-container">
            <ReactPlayer 
              url={recordedVideoURL} 
              controls 
              width={300} 
              height={230} 
              volume={1.0}
            />
          </div>
        </>
      )}

      <div className="video-recording-handlers text-end mt-4">
        {!capturing && recordedChunks.length === 0 && (
          <CButton 
            className="secondary_btn mt-lg-0 mt-3"
            onClick={handleStartCaptureClick}
          >
            {t('Start')}
          </CButton>
        )}
        {capturing && (
          <>
            <CButton 
              onClick={handleCancelClick}
              className="secondary_outline_btn me-lg-4 me-2 mt-lg-0 mt-3"
            >
              {t('Cancel')}
            </CButton>
            <CButton 
              onClick={handleStopCaptureClick}
              className="secondary_btn mt-lg-0 mt-3"
            >
              {t('Stop')}
            </CButton>
          </>
        )}
        {recordedChunks.length > 0 && (
          <>
            <CButton 
              onClick={handleCancelClick}
              className="secondary_outline_btn me-lg-4 me-2 mt-lg-0 mt-3"
            >
              {t('Cancel')}
            </CButton>
            <CButton 
              onClick={handlePreview}
              className="primary_outline_btn me-2 mt-lg-0 mt-3"
            >
              {t('Preview')}
            </CButton>
            <CButton 
              onClick={handleDownload} 
              disabled={loading}
              className="primary_btn mt-lg-0 mt-3"
            >
              {loading ? <Spinner /> : t('Submit')}
            </CButton>
          </>
        )}
      </div>
    </>
  );
};

export default VideoRecorder;
